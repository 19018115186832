import React from 'react'

class RiddleCodeChecker extends React.Component {
  render() {
    return (
      <div className="row riddle-code">
        <div className="col-12 text-center">
          <div className="my-2">
            Code
          </div>
          <input></input>
        </div>
      </div>
    )
  }
}

export default RiddleCodeChecker;